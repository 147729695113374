<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div
          class="slipt-content-inner ms-tableCell story-content rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title">Servicios</h2>
            <div class="service-wrapper">
              <!-- Start Single Service -->
              <div
                class="service"
                v-for="(service, i) in serviceContent"
                :key="i"
              >
                <h4>
                  <router-link :to="service.targetRoute">{{
                    service.title
                  }}</router-link>
                </h4>
                <p>
                  {{ service.desc }}
                </p>
                <h4 v-if="service.customService">
                  {{ service.customService }}
                </h4>
                <p v-if="service.lastItem">
                  Please
                  <router-link :to="service.contact">Contact us</router-link>
                </p>
              </div>
              <!-- End Single Service -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/service/services-1.jpg"),
        serviceContent: [
          {
            targetRoute: "/mano-alzada",
            title: "Dibujos a mano alzada",
            desc: `Creo dibujos personalizados a mano alzada, utilizando principalmente la técnica de la plumilla.`,
          },
          {
            targetRoute: "/bodas-eventos",
            title: "Dibujos para bodas y eventos",
            desc: `Uno de mis servicios más populares es la creación de dibujos para bodas y otros eventos especiales.`,
          },
          {
            targetRoute: "/decoracion",
            title: "Dibujos para decoración",
            desc: `Los dibujos a mano pueden ser una excelente opción para decorar interiores de casas y negocios`,
          },
          {
            targetRoute: "/service-details",
            customService: "¿Necesitas algo mas?",
            contact: "/contact",
            lastItem: true,
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
